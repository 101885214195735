import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UxSidebar() {
  return (
    <>
      <div className="ux-sidebar">
        <nav className="sidebar-top bg-light leading-[3rem] bg-[#c8beb6]">
          <Link to="/account/login">
            <FontAwesomeIcon icon="fa-solid fa-lock" />
          </Link>
        </nav>
      </div>
    </>
  );
}
