import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactDOM from "react-dom/client";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

import UxBaseline from "./components/uxBaseline";
import Dashboard from "./pages/dashboard";
import InvoiceView from "./pages/invoiceView";
import PaymentMethod from "./pages/paymentMethod";

library.add(fab, fas, far);

const root = ReactDOM.createRoot(
  document.getElementById("root") // as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<UxBaseline />}>
        <Route index element={<Navigate to="/dashboard" replace={true} />} />
        <Route path="invoice/:invoiceId" element={<InvoiceView />} />
        <Route path="payments/:clientId" element={<PaymentMethod />} />
        <Route path="">
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
