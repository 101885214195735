import { usePlaidLink } from "react-plaid-link";
import { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
const PaymentMethodStripe = () => {
  const stripe = useStripe();
  const elements = useElements();
  let handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const result = await stripe.confirmCardSetup(
      "seti_1NxHE3EortwXv2Kx2rC5eN4s_secret_OkmnE0etGMIWLUaAC87Qx2a99xzewBK",
      {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: "Todd Crane",
          },
        },
      }
    );
    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        alert("Successful");
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement />
        {/* <PaymentElement /> */}
        <button disabled={!stripe} type="submit">
          Submit
        </button>
      </form>
    </>
  );
};
export default function PaymentMethod() {
  const params = useParams();
  const [token, setToken] = useState(null);
  useEffect(() => {
    const createLinkToken = async () => {
      const response = await fetch("https://api.handyman.sh/plaid/link_token", {
        method: "POST",
        body: JSON.stringify({ clientId: params["clientId"] }),
      });
      const { link_token } = await response.json();
      setToken(link_token);
    };
    createLinkToken();
  }, [params]);
  const { open, ready } = usePlaidLink({
    token: token,
    onSuccess: async (public_token, metadata) => {
      metadata["clientId"] = params["clientId"];
      const response = await fetch(
        "https://api.handyman.sh/plaid/exchange_token",
        {
          method: "POST",
          body: JSON.stringify(metadata),
        }
      );

      console.log(response);
      // console.log("pt", public_token);
      // console.log("md", metadata);
    },
  });

  const stripePromise = loadStripe(
    "pk_live_51A7yyZEortwXv2KxfOc8DYludeERI24y4FETIUvOJLUxcUNJe5v4jl3YXiIwUFUwyWv2VvhmTWi5v04mXDPPvNou00QZCbimvj"
  );
  const stripeOptions = {
    clientSecret:
      "seti_1NxHE3EortwXv2Kx2rC5eN4s_secret_OkmnE0etGMIWLUaAC87Qx2a99xzewBK",
  };
  return (
    <>
      <div className="w-full h-screen ">
        <main className="flex">
          <div className="w-96 m-auto bg-white">
            <div className="p-4 m-4">
              <div class=" border-gray-200 py-6 px-4 sm:px-6">
                <button
                  onClick={() => open()}
                  disabled={!ready}
                  className="w-full border border-transparent  bg-[#1b2730] py-3 px-4 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  Connect a bank account
                </button>
                or
                <Elements stripe={stripePromise} options={stripeOptions}>
                  <PaymentMethodStripe className="test2" />
                </Elements>
                There's a 3% fee to all credit card transactions
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
