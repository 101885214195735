export default function UxHeader() {
  return (
    <>
      <header className=" shadow">
        <nav className="navbar navbar-expand navbar-light navbar h-12 flex-none bg-[#f8f9fa] justify-start flex items-center relative px-4 py-2">
          <ul className="navbar-nav whitespace-nowrap text-right text-base leading-[3rem] flex-row flex pl-0 mb-0 list-none"></ul>
          <ul className="nav navbar-nav ml-auto whitespace-nowrap text-right text-base leading-[3rem] flex-row flex pl-0 mb-0 list-none">
            <li className="nav-item">
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
