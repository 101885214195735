import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function InvoiceView() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  let { invoiceId } = useParams();
  useEffect(() => {
    fetch(`https://api.handyman.sh/invoices/${invoiceId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          alert("not found");
        }
        throw response;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("error fetching data: ", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [invoiceId]);

  const invoice = data;
  if (error) return "Error";

  if (loading) return "Loading";

  return (
    <>
      <div className="w-[60rem] mx-auto">
        <div className="flex flex-col grow ">
          <div className="flex">
            <div className="">
              Simeon Networks
              <br />
              414 Union St
              <br />
              Ste 1900
              <br />
              Nashville, TN 37219
            </div>
            <div className="ml-auto">
              <h1 className="text-4xl pb-6 font-bold text-center">Invoice</h1>

              <table>
                <tbody>
                  <tr>
                    <th className="text-right pr-4">Invoice #</th>
                    <td>{invoice.invNum}</td>
                  </tr>
                  <tr>
                    <th className="text-right pr-4">Invoice Date</th>
                    <td>{new Date(invoice.invDate).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <th className="text-right pr-4">Invoice Amount</th>
                    <td>$ {invoice.invTotal.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th className="text-right pr-4">Due Date</th>
                    <td>{new Date(invoice.dueDate).toLocaleDateString()}</td>
                  </tr>
                </tbody>
              </table>
              <button className="p-4 mt-4 bg-red-400 w-full">Pay Now</button>
            </div>
          </div>
          <div>
            Bill To:
            <br />
            {invoice.billTo.name}
            <br />
            {invoice.billTo.address.streetAddress}
            <br />
            {invoice.billTo.address.unitNo && (
              <>
                Unit # {invoice.billTo.address.unitNo}
                <br />
              </>
            )}
            {invoice.billTo.address.city}, {invoice.billTo.address.state}{" "}
            {invoice.billTo.address.zipCode}
          </div>

          <table className="my-8 min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                >
                  Unit Price
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                >
                  Line Total
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {invoice["invProducts"] &&
                invoice["invProducts"].map((item, itemIdx) => (
                  <tr
                    className={itemIdx % 2 === 0 ? undefined : "bg-gray-50"}
                    key={itemIdx}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {item.qty}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {item.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-900">
                      $ {item.unitPrice.toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-900">
                      $ {(item.qty * item.unitPrice).toFixed(2)}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}></td>
                <th
                  colSpan={1}
                  className="text-right px-3 py-3.5 text-sm font-semibold text-gray-900"
                >
                  Subtotal:
                </th>
                <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                  $ {invoice.subTotal.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <th
                  colSpan={1}
                  className="text-right px-3 py-3.5 text-sm font-semibold text-gray-900"
                >
                  Taxes:
                </th>
                <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                  $ {(0).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <th
                  colSpan={1}
                  className="text-right px-3 py-3.5 text-sm font-semibold text-gray-900"
                >
                  Balance Fwd:
                </th>
                <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                  $ {invoice.balanceFwd.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <th
                  colSpan={1}
                  className="text-right px-3 py-3.5 text-sm font-semibold text-gray-900"
                >
                  Payments:
                </th>
                <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                  - $ {invoice.paymentsTotal.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <th
                  colSpan={1}
                  className="text-right px-3 py-3.5 text-sm font-semibold text-gray-900"
                >
                  Total Due:
                </th>
                <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                  $ {invoice.invTotal.toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </table>

          <button className="p-4 bg-red-400">Pay Now</button>
        </div>
      </div>
    </>
  );
}
